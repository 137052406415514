import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import * as styles from './TypedText.module.scss';

const TYPING_SPEED = 75;

const TypedText = ({ text, isActive = true }) => {
    const [state, setState] = useState({
        typedCharacters: 0,
        startedTyping: false,
    });

    useEffect(() => {
        let timer = '';

        const handleType = () => {
            setState(currentState => {
              if (isActive) {
                const typedCharacters = currentState.startedTyping ? currentState.typedCharacters + 1 : 0;

                if (typedCharacters < text.length) {
                    timer = setTimeout(handleType, TYPING_SPEED);
                }

                return ({
                  ...currentState,
                  typedCharacters,
                  startedTyping: true,
                });
              } else {
                return { ...currentState, typedCharacters: currentState.startedTyping ? currentState.typedCharacters : 0 };
              }
            });
        };

        setTimeout(handleType);

        return () => clearTimeout(timer);
    }, [isActive]);

    return (
        <p className={styles.root}>
          {text.substring(0, state.typedCharacters).replace(/\u00A0/g, ' ')}
          <span className='no-js'>{text}</span>
        </p>
    );
};

TypedText.propTypes  = {
    text: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
};

export default TypedText;
