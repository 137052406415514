import * as React from 'react';

import Layout from '../components/Layout';
import * as layoutStyles from '../components/Layout.module.scss';
import TextBlock from '../components/TextBlock';
import TypedText from '../components/TypedText';

const NotFoundPage = () => {
  return (
    <Layout>
      <main className={layoutStyles.wrapper}>
        <TextBlock>
          <TypedText text="Oops! That page doesn't exist..."></TypedText>
        </TextBlock>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
